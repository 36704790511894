/** Remove when material is gone **/
@use "@angular/material" as mat;

@include mat.core();

$smallstack-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$smallstack-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$smallstack-typography: mat.m2-define-typography-config(
  $font-family: "Inter var"
);

$smallstack-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $smallstack-primary,
      accent: $smallstack-accent
    ),
    typography: $smallstack-typography
  )
);

@include mat.all-component-themes($smallstack-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/variables";

// all our imports
@import "./styles/material-overwrites";
@import "./styles/animations";
@import "./styles/badges";

html {
  height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  zoom: 1.1;
}

* :focus {
  outline: none;
}

* {
  user-select: none;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

h1 {
  margin-top: 0px;
  font-size: 2em;
}

h2 {
  margin-top: 0px;
  font-size: 1.3em;
}

@mixin fullscreen() {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
}

.fullscreen-dialog {
  @include fullscreen();
}

.fullscreen-dialog {
  .mat-mdc-dialog-content,
  .mat-mdc-dialog-container {
    padding: 5px;
    margin: 5px;
  }
}

locale-selector {
  flag {
    zoom: 1.5;
  }
}

.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4));
  overflow: auto;
}

button.back-button {
  display: flex;
  align-items: center;
  color: white;
  border-color: white !important;
  font-size: 1em;
  padding: 10px 20px 10px 8px;
}

.link {
  font-size: 0.8em;
}

.link .mat-icon {
  font-size: 1em;
}

.dialog-icon {
  width: 100px;
  height: 100px;
  font-size: 100px;
  margin-bottom: 20px;
}

.icon-checked::after {
  content: url("./assets/icons/checked.svg");
}

.icon-error::after {
  width: 100px;
  height: 100px;
  font-size: 100px;
  margin-bottom: 20px;
  content: url("./assets/icons/error.svg");
}

.visitor-card {
  @apply bg-white bg-opacity-80 p-4 rounded-lg shadow-xl;
  width: 80%;
  height: 100%;
}

.visitor-card #intro,
.visitor-card #success,
.visitor-card #error,
.visitor-card #print-or-not,
.visitor-card #qr-code {
  text-align: center;
}

@media screen and (max-width: 959px) {
  .visitor-card {
    width: 99%;
  }
}

/* FORM STYLES */
.visitor-card #forms .mat-mdc-card,
.visitor-card #visitor .mat-card {
  background: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}
