// make checkbox a bit bigger
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  height: 35px !important;
  width: 35px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  display: unset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-caption,
.mat-checkbox-label {
  font-size: 18px;
}

.mat-mdc-icon-button.large {
  width: 48px;
  height: 48px;
  line-height: 48px;

  .mat-icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}

// locale selector
.mat-mdc-menu-panel {
  background-color: #252525;
  min-width: 50px !important;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(5px);
}
