@use "@angular/material" as mat;
@import "./variables";

.checkin-badge {
  background-color: var(--stack-primary);
  box-sizing: border-box;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  margin: 1px;

  &.primary {
    background-color: var(--stack-primary);
  }

  .badge-scoped {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    padding: 2px;
    margin-left: 6px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
